.templateEditor {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1)
}

.options {
    display: flex;
    align-items: center;
}

.options input {
    width: 300px;
    margin-right: 30px;
}

.isPersonal {
    display: flex;
    align-items: center;
}

.isPersonalLabel {
    padding-top: 6px;
    margin-left: 8px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}