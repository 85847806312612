#header-container{
    display: flex!important;
    flex-direction: row!important;
    justify-content: space-between;
    align-items: center;

}
.display-5{
    font-size: 2.8em;
    font-weight: 300;
    line-height: 1.2;
}

@media screen and (max-width: 516px) {
    .display-5 { font-size: 2.2em; }
}
@media screen and (max-width: 429px) {
    .display-5 { font-size: 1.9em; }
}
@media screen and (max-width: 380px) {
    .display-5 { font-size: 1.7em; }
}
@media screen and (max-width: 350px) {
    .display-5 { font-size: 1.4em; }
}