
/************************************************************
        contact persons card view

************************************************************/
.btn.form-control { font-size: 1.03em!important; }

.cards-info .main{
    font-size: 1.1em;
    line-height: 1em;
}
.cards-info .main:last-of-type{
    font-size: 1em;
}