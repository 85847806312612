/* BASE INSPINIAOVERRIDES START */

@media (max-width: 768px) {
    .navbar-static-side {
        position: absolute;
        display: block;
    }
}

/* BASE INSPINIAOVERRIDES END */
