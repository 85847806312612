.list {
    list-style: none;
    max-height: 300px;
    padding: 0;
    margin: 0;
    border-top: 1px solid #e5e6e7;
    border-bottom: 1px solid #e5e6e7;
    overflow-y: auto;
}

.list li {
    padding: 0.8em 0.4em;
    border-bottom: 1px solid #e5e6e7;
}


.list li:last-child {
    border-bottom: none;
}

.item {
    cursor: pointer;
}

.item svg {
    margin-right: 0.4em;
}

.list li:hover {
    background-color: #17a2b8;
    color: white;
}

.description {
    margin: 15px 0;
    padding-right: 15px;
}

.description.hidden {
    opacity: 0;
}
