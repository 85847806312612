.list {
    display: flex;
    flex-wrap: wrap;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.item:last-child {
    margin-bottom: 0;
}

.item button {
    border: 1px solid white !important;
    font-weight: bold;
}

.item a {
    color: white;
    font-weight: bold;
}
