.item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: default;
    user-select: none;
}

.item:hover {
    background-color: rgba(0,0,0,.075);
}

.item.marked {
    color: white;
    background-color: #2990d2;
}

.title {
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.controls {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.item:first-child .controls span:first-child, .item:last-child .controls span:last-child {
    opacity: 0.3;
}
