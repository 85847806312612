.container {
    display: block;
    position: relative;
    height: 22px;
    width: 22px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: 1px solid #e5e6e7;
}

/* On mouse-over */
.container:hover input ~ .checkmark {
    border: 1.5px solid #2d8f7b;
}

/* On mouse-over and disabled */
.container:hover input:disabled ~ .checkmark {
    border: 1px solid #e5e6e7;
    cursor: not-allowed;
}

/* When the checkbox is checked */
.container input:checked ~ .checkmark {
    background-color: #2d8f7b;
    border: 1px solid #2d8f7b;
}

/* When the checkbox is checked and disabled */
.container input:checked:disabled ~ .checkmark {
    background-color: #bbbbbb;
    border: 1px solid #bbbbbb;
    cursor: not-allowed;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
