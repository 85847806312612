.responsiveFlexContainer {
    display: flex;
}

.activityCol {
    margin-right: 1rem;
    padding-right: 1rem;
    border-style: solid;
    border-color: lightgray;
    border-width: 0 1px 0 0;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dateTimeColumn {
    min-width: 150px;
    width: 150px;
    margin-left: 1rem;
}

@media screen and (max-width: 1224px) {
    .responsiveFlexContainer {
        flex-direction: column
    }

    .dateTimeColumn {
        margin-left: 0;
    }
}
