.list {
    max-height: 500px;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
}

.item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.item:last-child {
    border-bottom: none;
}

.selected {
    background-color: #2990d2;
    color: white;
}